<template>
  <div
    class="contact d-flex flex-column h-100 position-relative"
    :class="{'overflow-hidden': $device.isMobile}"
  >
    <base-header
      :title="$t('聯絡我們')"
      :left-arrow="true"
      :breadcrumb="breadcrumb"
      :mode="$device.isMobile ? 'secondary' : 'primary'"
    ></base-header>

    <div
      v-if="isReportSuccess"
      class="d-flex justify-content-center align-items-center h-100"
    >
      <div class="report-success d-flex flex-column">
        <b-img
          width="200"
          height="200"
          class="mx-auto mb-4"
          :srcset="require('@/assets/img/personal/report-success.png?srcset')"
        ></b-img>
        <div class="text-font-grayscale-1 text-center">
          {{ reportSuccessLabel }}
        </div>
        <button
          class="btn btn-primary mt-4"
          size="md"
          @click="$router.push({name: 'personal'})"
        >
          {{ $t('確定') }}
        </button>
      </div>
    </div>
    <div v-else class="wrapper scroll-bar">
      <div class="contact-container">
        <div
          v-if="!$device.isMobile"
          class="text-18 font-weight-bold text-center mb-2"
        >
          聯絡我們
        </div>
        <div v-if="!$device.isMobile" class="text-font-grayscale-1 text-center">
          若您有任何需要我們服務的地方，請填寫以下表單表達您的意見
        </div>
        <b-form class="question-form p-3" @submit.prevent="onSubmit">
          <div class="text-font-grayscale-1 mb-2 text-14">
            問題類別<span class="text-negative">＊</span>
          </div>
          <dropdown
            v-model="questionTypeValue"
            :title="$t('問題類別')"
            :options="questionTypes"
            icon-class="text-primary"
            class="bg-white"
          ></dropdown>
          <b-form-invalid-feedback
            id="input-phoneNum-feedback"
            :state="formData.isTypeValid"
            class="mt-1"
          >
            <div v-if="formData.questionType === '請選擇'" class="error">
              ＊{{ $t('請選擇類別') }}
            </div>
          </b-form-invalid-feedback>
          <div class="text-font-grayscale-1 mb-2 mt-4 text-14">
            問題內容<span class="text-negative">＊</span>
          </div>
          <b-form-textarea
            v-model="formData.content"
            class="qeustion-content text-14"
            :placeholder="contentPlaceholder"
            rows="6"
            no-resize
          ></b-form-textarea>
          <b-form-invalid-feedback
            id="input-phoneNum-feedback"
            :state="formData.isContentValid"
            class="mt-1"
          >
            <div v-if="!formData.contnet" class="error">
              ＊{{ $t('請輸入內容') }}
            </div>
          </b-form-invalid-feedback>
          <div class="text-font-grayscale-1 mb-2 mt-4 text-14">
            上傳圖片（最多3張，每張限制在 2MB以內）
          </div>
          <div class="d-flex">
            <UploadImg
              v-for="index in maxImageFiles"
              :key="index"
              class="mr-3"
              breadcrumb-name="回報問題"
              @upload-img="uploadImg"
              @remove-img="removeImg"
            ></UploadImg>
          </div>
          <button type="submit" class="btn btn-primary w-100 mt-4" size="md">
            {{ $t('送出') }}
          </button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import Dropdown from '@/components/Base/Dropdown';
import UploadImg from '@/components/Base/UploadImg';

import userService from '@/services/user';
import {putObject} from '@/lib/base/uploadFile';

export default {
  components: {
    BaseHeader,
    Dropdown,
    UploadImg,
  },
  data() {
    return {
      breadcrumb: [
        {
          name: this.$t('個人'),
          enable: true,
          router: 'personal',
        },
        {
          name: this.$t('聯絡我們'),
          enable: true,
          router: 'contact',
        },
      ],
      questionTypeValue: 'normal',
      formData: {
        questionType: '請選擇',
        content: '',
        images: [],
        isTypeValid: null,
        isContentValid: null,
      },
      questionTypes: [
        {
          value: 'normal',
          label: '請選擇',
          placeholder: '請輸入',
        },
        {
          value: 'NFT_NEXT_COURSE',
          label: '兌換千古無同局 NFT 賦能',
          placeholder:
            '請說明您要兌換的內容，舉例：兌換下一個程度的課程－晉段班',
          reportSuccess:
            '我們已經收到您的申請，預計於 3-5 個工作天內進行處理，如有缺漏文件或不符資格，將另行通知。',
        },
        {
          value: 'RANK_CERTIFIED',
          label: '棋力認證',
          placeholder: '請說明您的棋力，並附上實體證書的照片',
          reportSuccess:
            '我們已經收到您的申請，預計於 3-5 個工作天內進行調整，如有缺漏文件或不符資格，將另行通知。',
        },
        {
          value: 'BUG',
          label: '問題回報',
          placeholder:
            '請盡可能詳細描述您遇到的問題，並提供截圖，以便我們更好的為您提供幫助',
          reportSuccess: '感謝您對黑嘉嘉圍棋教室的支持與愛護，我們將盡快處理。',
        },
        {
          value: 'FEEDBACK',
          label: '意見反饋',
          placeholder: '請填寫您的問題和意見，以便我們更好的為您提供服務',
          reportSuccess:
            '感謝您對黑嘉嘉圍棋教室的支持與愛護，您寶貴的意見讓我們可以有機會為您提供更好的服務。',
        },
      ],
      isQuestionTypeEditing: false,
      contentPlaceholder: '請輸入',
      reportSuccessLabel: '',
      isReportSuccess: false,
      maxImageFiles: 3,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.env.isLoading;
    },
  },
  watch: {
    questionTypeValue(newValue) {
      if (newValue) {
        this.formData.isTypeValid = null;
        const questionType = this.questionTypes.find(
          (type) => type.value === newValue
        );
        this.contentPlaceholder = questionType.placeholder;
        this.reportSuccessLabel = questionType.reportSuccess;
        this.formData.questionType = questionType.value;
      }
    },
  },
  methods: {
    onSubmit() {
      if (this.isLoading) return;
      const invalid =
        this.formData.questionType === '請選擇' || !this.formData.content;

      this.formData.isTypeValid = false;
      this.formData.isContentValid = false;

      if (!invalid) {
        this.formData.isTypeValid = null;
        this.formData.isContentValid = null;
        this.createUserReport();
      }
    },
    async createUserReport() {
      try {
        this.$store.commit('env/setIsLoading', true);
        const promises = this.formData.images.map(async (image) => {
          if (image.key) {
            putObject({key: image.key, arrayBuffer: image.arrayBuffer});
          }
        });
        await Promise.all(promises);
        const keys = this.formData.images.map((image) => image.key);
        const data = {
          type: this.formData.questionType,
          content: this.formData.content,
          images: keys,
        };
        await userService.createUserReport(data);
        this.isReportSuccess = true;
      } catch (error) {
        this.$Message.error('問題回報失敗');
      } finally {
        this.$store.commit('env/setIsLoading', false);
      }
    },
    async uploadImg(imageFile) {
      this.formData.images.push(imageFile);
    },
    removeImg(key) {
      const removeIndex = this.formData.images.findIndex((imageFile) => {
        return imageFile.key === key;
      });
      this.formData.images.splice(removeIndex, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  .wrapper {
    overflow: overlay;
  }
  .btn-select-question-type {
    border: 1px solid #ced4da;
    border-radius: 4px;
    width: 100%;
    &:hover {
      border: 1px solid #ced4da;
    }
  }
  .qeustion-content {
    border: 1px solid #ced4da;
    border-radius: 4px;
  }
  .report-success {
    max-width: 300px;
    margin: 0 auto;
    .btn-primary {
      width: 200px;
      margin: 0 auto;
    }
  }
}

@media screen and (min-width: 768px) {
  .contact {
    .wrapper {
      height: calc(100vh - 80px);
      .contact-container {
        box-shadow: 0px 2px 7px 0px #c7c7c74d;
        border-radius: 10px;
        overflow: overlay;
        max-width: 720px;
        margin: 40px auto;
        padding: 40px 0;
        background: white;
        .question-form {
          max-width: 520px;
          margin: 0 auto;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .contact {
    .wrapper {
      .contact-container {
        max-width: 1140px;
        margin: 40px auto;
      }
    }
  }
}
</style>
