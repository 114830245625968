<template>
  <div class="uploadImg">
    <b-img
      v-show="previewImg"
      class="img"
      :class="imageFileName"
      :src="previewImg"
    ></b-img>
    <b-form-file
      v-model="imageFile"
      accept=".jpg, .png, .gif"
      :class="{'has-preview-img': previewImg}"
    ></b-form-file>
    <div v-show="previewImg" class="remove-img" @click="removeImg">
      <i class="icon-X"></i>
    </div>
  </div>
</template>

<script>
import {clientVersion} from '@/constant/env.js';

export default {
  props: {
    imageFileName: {
      type: String,
      default: 'image-file',
    },
    breadcrumbName: {
      type: String,
      default: '預設上傳圖片',
    },
  },
  data() {
    return {
      imageFile: null,
      previewImg: null,
      resetImg: false,
      imageKye: null,
    };
  },
  watch: {
    imageFile(newValue, oldValue) {
      if (this.resetImg) {
        this.resetImg = !this.resetImg;
        return;
      }
      if (!newValue && oldValue && !this.resetImg) {
        this.imageFile = oldValue;
      }
      this.previewImg = null;
      const maxAllowedSize = 2 * 1024 * 1024;
      if (this.imageFile.size > maxAllowedSize) {
        return this.$Message.error('圖片大小超過限制');
      }
      const fileReader = new FileReader();
      const fileReaderBase64 = new FileReader();
      fileReader.onload = (event) => {
        const fileName = this.imageFile.name;
        const key = `${clientVersion}/${
          this.breadcrumbName
        }/${this.$day().valueOf()}-${fileName}`;
        this.imageKye = key;
        this.$emit('upload-img', {
          arrayBuffer: event.target.result,
          fileName,
          key,
        });
      };
      fileReader.readAsArrayBuffer(this.imageFile);

      fileReaderBase64.onload = (event) => {
        this.previewImg = event.target.result;
      };
      fileReaderBase64.readAsDataURL(this.imageFile);
    },
  },
  methods: {
    removeImg() {
      this.$emit('remove-img', this.imageKye);
      this.resetImg = true;
      this.previewImg = null;
      this.imageFile = null;
      this.imageKye = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-file {
  width: 54px;
  height: 54px;
  &.has-preview-img {
    opacity: 0;
  }
  /deep/ .custom-file-input {
    height: 100%;
    &.is-invalid:focus ~ .custom-file-label {
      border-color: transparent;
      box-shadow: none;
    }
    &:focus ~ .custom-file-label {
      box-shadow: none;
    }
  }
  /deep/ .custom-file-label {
    border: 1px dashed transparent;
    height: 100%;
    background: transparent;
    margin-bottom: 0;
    border-radius: 3px;
    &::after {
      height: 100%;
      background: white;
      padding: 0;
      content: '\e954';
      width: 100%;
      font-family: 'icomoon' !important;
      border: 1px dashed #ced4da;
      border-radius: 3px;
      font-size: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $font-grayscale-2;
    }
    .form-file-text {
      display: none !important;
    }
  }
}
.uploadImg {
  width: 54px;
  height: 54px;
  position: relative;
  .img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 4px;
  }
  .remove-img {
    color: white;
    background: $grayscale-30;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-content: center;
    top: -8px;
    right: -8px;
    cursor: pointer;
    .icon-X {
      line-height: 30px;
    }
  }
}
</style>
